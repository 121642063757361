/*
 Theme Name: Insut
 Theme URI: 
 Author: Mosharof
 Author URI: 
 Description: Insut - Insurance HTML5 Responsive Template
 Version: 1.0
 License:
 License URI:
 */

/*------------------------------------------------------
/  All Preset Css
/------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

html, body{
    margin: 0;
    padding: 0;
}
body{
    font-family: 'Roboto', sans-serif;  
    font-size: 16px;
    line-height: 28px;
    color: #14212b;
    letter-spacing: 0;
    font-weight: 400;
    word-break: break-word;
}
h1, h2, h3, h4, h5, h6{
    font-family: 'Poppins', sans-serif;
    color: #14212b;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0 0 15px;
    line-height: 1.2;
}
a{
    color: #14212b;
    text-decoration: none;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
a:hover{
    color: #ff4a17;
    text-decoration: none;
}
a:focus{
    outline: 0;
    text-shadow: none;
    box-shadow: none;
}
/*---- Insut Btn ----*/
.quomodo-btn{
    display: inline-block;
    background: #ff4a17;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    border: none;
    border-radius: 0;
    height: 60px;
    line-height: 60px;
    z-index: 2;
    padding: 0 36px;
    overflow: hidden;
    position: relative;
    
   -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.quomodo-btn::after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 100%;
    width: 0%;
    content: '';
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    background: #14212b;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.quomodo-btn:hover::after {
    visibility: visible;
    opacity: 1;
    width: 100%;
}
.quomodo-btn i{
    position: relative;
    font-size: 16px;
    top: 1px;
    padding-left: 5px;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.quomodo-btn:hover i{
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
}
.quomodo-btn:hover{
    color: #FFF;
}
.btn-color-2{
    background: #237a40;
}
.btn-radius{
    border-radius: 30px;
}
button:focus,
button:hover{
    outline: none;
    border: none;
}
/*---- Insut Section Title ----*/
.sub-title{
    font-size: 16px;
    color: #14212b;
    line-height: 20px;
    font-weight: 700;
    margin: 0 0 16px;
}
.sub-title .border-left{
    background: #ff4a17;
    display: inline-block;
    width: 4px;
    height: 30px;
    left: -20px;
    top: 10px;
    border: none !important;
    position: relative;
}
.sub-title .border-left.bl-2{
    background: #237a40;
}
.sub-title .border-left.bl-3{
    background: #ffc13e;
}
.sub-title.white{
    color: #fff;
}
.sub-title-2{
    height: 30px;
    position: relative;
    text-align: center;
    display: inline-block;
    margin: 0 0 10px;
}
.sub-title-2 span{
    font-size: 16px;
    line-height: 30px;
    color: #14212b;
    font-weight: 700;
    background: #fff;
    height: 100%;
    border-left: 4px solid #ff4a17;
    border-right: 4px solid #ff4a17;
    padding: 0 19px;
    display: inline-block;
}
.sec-title{
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -1.8px;
    margin: 0 0 66px;
}
.sec-title.white{
    color: #fff;
}
.sec-desc{
    font-size: 20px;
    line-height: 32px;
    font-weight: 500;
    margin: 0 0 50px;
}
.water-text{
    font-size: 258px;
    color: #1a1f2c;
    line-height: 258px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    -webkit-text-stroke: 1px #fff;
    opacity: .05;
    text-transform: uppercase;
    left: 0;
    right: 0;
    position: absolute;
    top: 10px;
    text-align: center;
    margin: 0 auto;
}
/*--- Video Btn ----*/
.popup-video{
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: 50%;
    font-size: 18px;
    color: #237a40;
    letter-spacing: -.54px;
    line-height: 120px;
    text-align: center;
    display: inline-block;
    background: #fff;
}
.popup-video:hover{
    color: #fff;
    background: #237a40;
}
.pv-2{
    background: #ffc13e;
    color: #14212b;
}
.pv-2:hover{
    background: #14212b;
    color: #ffc13e;
}
.ripple-anim{
    animation: button-ripple 3s linear infinite;
}
@keyframes button-ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 
        0 0 0 1px rgba(255, 255, 255, 0.2), 
        0 0 0 3px rgba(255, 255, 255, 0.2), 
        0 0 0 5px rgba(255, 255, 255, 0.2)
    }
    to {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 
        0 0 0 4px rgba(255, 255, 255, 0.2), 
        0 0 0 25px rgba(255, 255, 255, 0), 
        0 0 0 45px rgba(255, 255, 255, 0)
    }
}
@keyframes halfBounce {
    0%,
    100% {
        transform: scale(.5)
    }
    50% {
        transform: scale(1)
    }
}
@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}
/*---- Insut Section Padding ----*/
.noPadding{
    padding: 0;
}
.noPaddingLeft{
    padding-left: 0;
}
.noPaddingRight{
    padding-right: 0;
}
.noPaddingTop{
    padding-top: 0;
}
.mb-120{
    margin-bottom: 120px;
}
.m-top-80{
    margin-top: -80px;
}
svg g path{
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
}